<template>
  <div class="index">
    <div class="passCheck">
      <el-dialog title="请输入密码后进入系统" width="35%" :visible="activeName==0">
        <avue-form :option="option" v-model="form" v-if="box" @submit="handleSave"></avue-form>
      </el-dialog>
    </div>

    <div v-if="activeName != 0">
      <div class="header">
        <img src="/img/header.jpg" alt width="100%" height="210px" />
        <div class="title">
          <p>
            小黄牛供应链
            <br />
            <small>可视化数据大屏</small>
          </p>
        </div>
        <el-menu
          class="nav"
          mode="horizontal"
          :default-active="activeName"
          background-color="rgba(0,0,0,0)"
          text-color="#fff"
          @select="handleSelect"
          active-text-color="#00baff"
        >
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            大屏管理
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-location-information"></i>
            地图管理
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-folder-opened"></i>
            分类管理
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-set-up"></i>
            数据源管理
          </el-menu-item>
          <el-menu-item index="5" @click="goApi">接口文档</el-menu-item>
          <el-menu-item index="6" @click="goData">在线文档</el-menu-item>
        </el-menu>
      </div>
      <el-scrollbar class="main">
        <list v-if="activeName==1"></list>
        <maps v-else-if="activeName==2"></maps>
        <category v-else-if="activeName==3"></category>
        <db v-else-if="activeName==4"></db>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import list from "./list/index";
import maps from "./list/map";
import category from "./list/category";
import db from "./list/db";
import config from "../config";
import Cookies from "js-cookie";
export default {
  name: "index",
  components: {
    list,
    maps,
    category,
    db
  },
  data() {
    return {
      activeName: "0",
      form: {},
      option: {
        column: [
          {
            label: "密码",
            span: 24,
            type: "password",
            labelWidth: 100,
            prop: "password"
          }
        ]
      },
      box: false
    };
  },
  created() {
    if (this.$route.path == "/") {
      this.checkIsHasPassword();
    }
  },
  methods: {
    handleSave(form, done) {
      done();
      if (form.password == config.systemPassword) {
        this.activeName = 1;
        this.box = false;
        Cookies.set("canUseSystem", 1, {
          expires: 1
        });
      } else {
        this.$message.error("密码错误");
      }
    },
    checkIsHasPassword() {
      if (config.systemPassword && Cookies.get("canUseSystem") != 1) {
        this.activeName = 0;
        this.box = true;
      } else {
        this.activeName = 1;
        this.box = false;
      }
    },
    goApi() {
      window.open("/visual-api/doc.html");
    },
    goData() {
      window.open("https://www.kancloud.cn/smallwei/avue-doc");
    },
    handleSelect(key) {
      if (["5", "6"].includes(key)) {
        return;
      }
      this.activeName = key;
    }
  }
};
</script>
<style lang="scss">
.index {
  height: 100%;
  .passCheck {
    .el-dialog__header {
      padding: 20px 20px;
    }
    .el-dialog__body {
      background-color: #000000;
    }
    .el-dialog__close {
      display: none;
    }
  }

  .header {
    position: relative;
    padding: 0;
    height: auto !important;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
        display: flex;
        align-items: center;
      }
    }
    .title {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 34px;
      color: rgb(0, 186, 255);
      font-weight: 500;
    }
    .title small {
      font-size: 18px;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
}
</style>