<!-- 地图配置 -->
<template>
  <div>
    <!-- <el-form-item label="开启轮播">
      <avue-switch v-model="main.activeOption.banner"></avue-switch>
    </el-form-item>
    <template v-if="main.activeOption.banner">
      <el-form-item label="轮播时间">
        <avue-input v-model="main.activeOption.bannerTime"></avue-input>
      </el-form-item>
    </template> -->
    <el-form-item label="地图透明">
      <avue-switch v-model="main.activeOption.hideMap"></avue-switch>
    </el-form-item>
    <el-form-item label="开启飞线图">
      <avue-switch v-model="main.activeOption.mapLines"></avue-switch>
    </el-form-item>
    <div v-if="main.activeOption.mapLines">
      <el-form-item label-width="0" >
        <el-button size="mini"
                  type="primary"
                  class="block"
                  @click="openCode('mapLinesData')">飞线图数据配置</el-button>
      </el-form-item>
      <el-form-item label="线颜色">
        <avue-input-color v-model="main.activeOption.linesColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="线宽度">
        <avue-slider v-model="main.activeOption.linesWidth"
                     :step="1"></avue-slider>
      </el-form-item>
      <el-form-item label="线阴影颜色">
        <avue-input-color v-model="main.activeOption.linesShadowColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="线动效图标">
        <avue-select :dic="linesIcons"
                     v-model="main.activeOption.linesIcon"
                     placeholder="请选择图标"></avue-select>
      </el-form-item>
    </div>
    <el-form-item label="开启雷达">
      <avue-switch v-model="main.activeOption.mapRadar"></avue-switch>
    </el-form-item>
    <div v-if="main.activeOption.mapRadar">
      <el-form-item label-width="0" >
        <el-button size="mini"
                  type="primary"
                  class="block"
                  @click="openCode('mapRadarData')">雷达数据配置</el-button>
      </el-form-item>
      <el-form-item label="雷达颜色">
        <avue-input-color v-model="main.activeOption.radarColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="雷达波纹颜色">
        <avue-input-color v-model="main.activeOption.radarShadowColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="雷达大小">
        <avue-slider v-model="main.activeOption.radarSize"
                     :min="20"
                     :step="1"></avue-slider>
      </el-form-item>
      <el-form-item label="开启轮播">
        <avue-switch v-model="main.activeOption.mapTooltipSlideshow"></avue-switch>
      </el-form-item>
      <el-form-item label="轮播卡片背景颜色">
        <avue-input-color v-model="main.activeOption.mapTooltipSlideBackColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="轮播卡片字体颜色">
        <avue-input-color v-model="main.activeOption.mapTooltipSlideTextColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="轮播速度">
        <avue-input-number v-model="main.activeOption.mapTooltipSlideSpeed"></avue-input-number>
      </el-form-item>
    </div>
    <!-- <el-form-item label="区域详细信息展示">
      <avue-switch v-model="main.activeOption.mapTooltip"></avue-switch>
    </el-form-item>
    <div v-if="main.activeOption.mapTooltip">
      <el-form-item label-width="0" >
        <el-button size="mini"
                  type="primary"
                  class="block"
                  @click="openCode('mapTooltipData')">详细信息内容配置</el-button>
      </el-form-item>
      
      
    </div> -->
    <el-form-item label="地图外边框">
      <avue-switch v-model="main.activeOption.showMapOuter"></avue-switch>
    </el-form-item>
    <div v-if="main.activeOption.showMapOuter">
      <el-form-item label="外边框颜色">
        <avue-input-color v-model="main.activeOption.showMapOuterColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="外边框阴影颜色">
        <avue-input-color v-model="main.activeOption.showMapOuterShadow"></avue-input-color>
      </el-form-item>
      <el-form-item label="边框阴影大小">
        <avue-slider v-model="main.activeOption.showMapOuterShadowSize"
                     :step="1"></avue-slider>
      </el-form-item>
      <el-form-item label="外外边框宽度">
        <avue-slider v-model="main.activeOption.strokeWidth"
                     :step="1"></avue-slider>
      </el-form-item>
    </div>
    <template v-if="main.activeOption.type===0">
      <el-form-item label="地图选择">
        <avue-select :dic="main.DIC.MAP"
                     v-model="main.activeOption.mapData"
                     placeholder="请选择地图"></avue-select>
      </el-form-item>
      <el-form-item label="地图比例">
        <avue-slider v-model="main.activeOption.zoom"
                     :max="5"
                     :step="0.1"></avue-slider>
      </el-form-item>
      <el-form-item label="字体大小">
        <avue-input-number v-model="main.activeOption.fontSize"></avue-input-number>
      </el-form-item>
      <el-form-item label="字体高亮颜色">
        <avue-input-color v-model="main.activeOption.empColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="字体颜色">
        <avue-input-color v-model="main.activeOption.color"></avue-input-color>
      </el-form-item>
      <el-form-item label="区域线">
        <avue-input-number v-model="main.activeOption.borderWidth"></avue-input-number>
      </el-form-item>
      <el-form-item label="区域颜色">
        <avue-input-color v-model="main.activeOption.areaColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="区域高亮颜色">
        <avue-input-color v-model="main.activeOption.empAreaColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="边框颜色">
        <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
      </el-form-item>

    </template>
    <template v-if="main.activeOption.type===1">
      <el-form-item label="图片地址">
        <avue-input v-model="main.activeOption.img"></avue-input>
      </el-form-item>
      <el-form-item label="地图比例">
        <avue-slider v-model="main.activeOption.scale"
                     :max="300"></avue-slider>
      </el-form-item>
    </template>

    <codeedit @submit="codeClose"
              :type="code.type"
              v-model="code.obj"
              :visible.sync="code.box"></codeedit>
  </div>
</template>

<script>
import codeedit from '../../page/group/code';
export default {
  inject: ["main"],
  data(){
    return {
      code: {
        box: false,
        type: '',
        obj: '',
      },
      linesIcons:[
        {label:'arrow',value:'arrow'},
        {label:'circle',value:'circle'},
        {label:'rect',value:'rect'},
        {label:'triangle',value:'triangle'},
        {label:'diamond',value:'diamond'},
        {label:'pin',value:'pin'},
        {label:'none',value:'none'},
      ]
    }
  },
  created(){
    // this.code.obj = this.main.activeOption.mapLinesData
  },
  components:{
    codeedit,
  },
  computed:{
  },
  watch:{
   
  },
  methods:{
    codeClose (value) {
      this.main.activeOption[this.code.type] = value;
    },
    openCode (type) {
      this.code.obj = this.main.activeOption[type]
      this.code.type = type;
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>